import React from "react";
import {Button} from "react-bootstrap";

function UserDeleteForm(props) {
    const {formSubmitHandler, user, modalSubmitHandler} = props
    const submitHandler = (e) => {
        e.preventDefault();
        formSubmitHandler(user);
        modalSubmitHandler();
    };

    return (
        <>
            <Button variant="danger" onClick={submitHandler}>
                Delete
            </Button>
        </>
    );
}

export default UserDeleteForm;

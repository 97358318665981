import { useContext } from "react";
import UserInfo from "../components/UserInfo";
import AuthContext from "../context/AuthContext";
import Login from "../views/loginPage";
import { Row, Col } from "react-bootstrap";
import Svg from "../components/Svg";

const Home = () => {
  const { user } = useContext(AuthContext);
  return (
    <div className="flex">
      {user ? (
        <>
          <Row className="me-auto mx-auto">
            <Col md={6} className="mb-2">
              <h1 className="mb-5">Welcome to 'Generic Management' Project</h1>
              <UserInfo user={user}/>
            </Col>
            <Col md={6} className="mb-2">
              <Svg />
            </Col>
          </Row>
        </>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default Home;

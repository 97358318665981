import {useContext} from "react";
import AuthContext from "../context/AuthContext";
import React, {Component} from "react";
import {Navbar, NavDropdown, Form, Button, Nav} from "react-bootstrap";
import {Link} from "react-router-dom";
import Container from "react-bootstrap/Container";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faDiagramProject,
    faHandHoldingHand,
} from "@fortawesome/free-solid-svg-icons";

const NavbarComp = () => {
    const {user, logoutUser} = useContext(AuthContext);
    return (
        <Navbar bg="light" expand="lg" sticky="top">
            <Container fluid>
                <Navbar.Brand href="/">
                    <FontAwesomeIcon icon={faDiagramProject}/> HIT - Management{" "}
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="navbarScroll"/>
                <Navbar.Collapse id="navbarScroll">
                    <Nav
                        className="mr-auto my-2 my-lg-0"
                        style={{maxHeight: "150px"}}
                        navbarScroll
                    >
                        {user ? (
                            <>
                                <Nav.Link href="http://127.0.0.1:8000/admin" target="_blank">
                                    Admin Panel
                                </Nav.Link>
                                <NavDropdown title="Users" id="navbarScrollingDropdown">
                                    <NavDropdown.Item as={Link} to="/register">
                                        Register User
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={Link} to="/manage">
                                        Manage User
                                    </NavDropdown.Item>
                                </NavDropdown>
                                <Nav.Link as={Link} to="/applications">
                                    Manage Applications
                                </Nav.Link>
                                <Nav.Link as={Link} to="/measurements">
                                    Manage Measurements
                                </Nav.Link>
                                <Navbar.Toggle/>
                            </>
                        ) : (
                            <Nav.Link as={Link} to="/login">
                                Login
                            </Nav.Link>
                        )}
                    </Nav>
                    {user ? (
                        <Form className="justify-content-end">
                            <Button variant="outline-success" onClick={logoutUser}>
                                Logout
                            </Button>
                        </Form>
                    ) : undefined}
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
};

export default NavbarComp;

import React, {
  Fragment,
  useState,
  useContext,
} from "react";
import AuthContext from "../context/AuthContext";
import { Button, Container, Row, Col, Form, InputGroup } from "react-bootstrap";
import ListGroupComp from "../components/ListGroupComp";
import _ from "lodash";

function Measurements() {
  const { addMeasurement, removeMeasurement, measurementsList } =
    useContext(AuthContext);

  const [applicationName, setApplicationName] = useState("");
  const [newMeasurement, setNewMeasurement] = useState({ name: "", unit: "" });

  const saveNewMeasurement = () => {
    addMeasurement(newMeasurement);
    setNewMeasurement({ name: "", unit: "" });
  };

  const deleteMeasurement = (masurement_name) => {
    removeMeasurement(masurement_name);
  };

  return (
    <Fragment>
      <Container className=" my-5">
        <h1 className="text-center">Manage Measurements</h1>
        <Row className="mt-5">
          <Col xs lg="5">
            <h3 className="text-center">Current Measurements</h3>
            <ListGroupComp
              array={measurementsList}
              onClick={deleteMeasurement}
              isMeasurements
            />
          </Col>
          <Col>
            <h3 className="text-center">Add Measurements</h3>
            <InputGroup className="my-4 text-center justify-content-center">
              <Form.Group>
                <Form.Label className="mb-2">Add Measurement:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Measurement name"
                  value={newMeasurement.name}
                  onChange={(e) =>
                    setNewMeasurement({
                      ...newMeasurement,
                      name: e.target.value,
                    })
                  }
                ></Form.Control>
                <Form.Control
                  className="mt-2"
                  type="text"
                  placeholder="Measurement value type"
                  value={newMeasurement.unit}
                  onChange={(e) =>
                    setNewMeasurement({
                      ...newMeasurement,
                      unit: e.target.value,
                    })
                  }
                ></Form.Control>
                <Button
                  className="mt-3 text-center"
                  variant="secondary"
                  onClick={saveNewMeasurement}
                >
                  Save Measurement
                </Button>
              </Form.Group>
            </InputGroup>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Measurements;

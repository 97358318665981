export const formInputs = [

    {
        userKey: 'email',
        label: "Email",
        placeholder: 'Enter email / username',
        type: 'email',
    },
    {
        userKey: 'clinic_name',
        label: "Clinic Name",
        placeholder: 'Enter clinic name',
    },
    {
        userKey: 'clinic_code',
        label: 'Clinic Code',
        placeholder: 'Enter clinic code',
    },
]


export const firebaseInputs = [
    {
        userKey: 'fb_authDomain',
        label: 'Firebase Authorization Domain',
        placeholder: 'authDomain',
    },
    {
        userKey: 'fb_databaseURL',
        label: 'Firebase Database URL',
        placeholder: 'databaseURL',
    },
    {
        userKey: 'fb_storageBucket',
        label: 'Firebase Storage Bucket',
        placeholder: 'storageBucket',
    },
    {
        userKey: 'fb_apiKey',
        label: 'Firebase API Key',
        placeholder: 'apiKey',
    },
    {
        userKey: 'fb_apiKeyPost',
        label: 'Firebase API V1 Key',
        placeholder: 'Api userKey post.',
    }
]

export const chatInputs = [
    {
        userKey: 'chat_authDomain',
        label: 'Chat Firebase Authorization Domain',
        placeholder: 'chat authDomain',
    },
    {
        userKey: 'chat_databaseURL',
        label: 'Chat Firebase Database URL',
        placeholder: 'chat databaseURL',
    },
    {
        userKey: 'chat_storageBucket',
        label: 'Chat Firebase Storage Bucket',
        placeholder: 'storageBucket',
    },
    {
        userKey: 'chat_apiKey',
        label: 'Chat Firebase Api Key',
        placeholder: 'apiKey',
    },

    {
        userKey: 'chat_projectId',
        label: 'Chat Firebase Project ID',
        placeholder: 'projectId',
    },

    {
        userKey: 'chat_appId',
        label: 'Chat Firebase App ID',
        placeholder: 'appId',
    },

    {
        userKey: 'chat_messagingSenderId',
        label: 'Chat Firebase messagingSenderId',
        placeholder: 'messagingSenderId',
    },
]

export const proxyInputs = [
    {
        userKey: 'public_url',
        label: 'Public Server URL\n(The url the application will be redirected to)',
        placeholder: 'https://my.public.domain/',
    },
    {
        userKey: 'private_url',
        label: 'Private Server URL\n(The url this server will call when proxying the login request)',
        placeholder: 'https://my.local.domain/',
    }
]
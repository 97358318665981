import React, { useEffect, useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import FormContainer from "../components/FormContainer";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Message from "../components/Message";

const LoginPage = ({ history }) => {
  const { loginUser, apiErrors } = useContext(AuthContext);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    username.length > 0 && loginUser(username, password);
  };

  useEffect(() => {}, [apiErrors]);

  return (
    <FormContainer>
      <Form onSubmit={handleSubmit} className="text-center p-3 w-100">
        <h1 className="mb-3 fs-3 fw-normal">Sign In</h1>
        <hr />
        {apiErrors && <Message variant={"danger"}>{apiErrors}</Message>}
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Control
            type="text"
            placeholder="Enter Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Control
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Group>
        <Button className="mt-3" type="submit" variant="primary">
          Sign In
        </Button>
      </Form>
    </FormContainer>
  );
};

export default LoginPage;

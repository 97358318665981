import React, { Fragment, useEffect, useContext, useState } from "react";
import AuthContext from "../context/AuthContext";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Message from "../components/Message";
import { validate } from "react-email-validator";
 
function UserForm(props) {
  const { formSubmitHandler, formLabel, user, applicationsList } = props;
  const { apiErrors, setApiErrors } = useContext(AuthContext);

  const [email, setEmail] = useState(user?.email ?? "");
  const [clinicName, setClinicName] = useState(user?.clinic_name ?? "");
  const [message, setMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const key_strings = {
    lowercase: "abcdefghijklmnopqrstuvwxyz",
    uppercase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ",
    number: "0123456789",
    symbol: "!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~",
  };

  function generatePassword() {
    let passwordCharSet = "";
    let length = 10;
    passwordCharSet += key_strings.lowercase;
    passwordCharSet += key_strings.uppercase;
    passwordCharSet += key_strings.symbol;
    passwordCharSet += key_strings.number;
    let password = "";
    for (let i = 0; i < length; i++) {
      password +=
        passwordCharSet[Math.floor(Math.random() * passwordCharSet.length)];
    }
    return password;
  }

  const password = generatePassword();
  const confirmPassword = password;

  const findFormErrors = () => {
    const newErrors = {};

    if (!email || email === "") newErrors.email = "cannot be blank!";
    else if (!validate(email)) newErrors.email = "Invalid Email";

    if (!clinicName || clinicName === "")
      newErrors.clinicName = "cannot be blank!";

    return newErrors;
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const newErrors = findFormErrors();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
    }
    if (password !== confirmPassword) {
      setMessage("Passwords do not match");
    } else {
      formSubmitHandler(
        email,
        password,
        confirmPassword,
        clinicName
      );
    }
  };

  useEffect(() => {}, [apiErrors]);

  return (
    <>
      <h1 className="text-center">{formLabel}</h1>
      <hr />

      <Form onSubmit={submitHandler} className="text-center">
        {apiErrors && <Message variant={"danger"}>{apiErrors}</Message>}
        <Form.Group controlId="email" className="my-2">
          <Form.Label>Email Address</Form.Label>
          <Form.Control
            type="email"
            placeholder="Enter email/username"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            isInvalid={!!errors.email}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.email}
          </Form.Control.Feedback>
        </Form.Group>

        <Form.Group controlId="clinicName" className="my-2">
          <Form.Label>Clinic Name</Form.Label>
          <Form.Control
            type="text"
            placeholder="Enter clinic name"
            value={clinicName}
            onChange={(e) => setClinicName(e.target.value)}
            isInvalid={!!errors.clinicName}
          ></Form.Control>
          <Form.Control.Feedback type="invalid">
            {errors.clinicName}
          </Form.Control.Feedback>
        </Form.Group>
        <Button className="mt-3 text-center" type="submit" variant="primary">
          Submit
        </Button>
      </Form>
    </>
  );
}

export default UserForm;

import React, {
    Fragment,
    useEffect,
    useContext,
    useState,
    useCallback,
    useMemo
} from "react";
import AuthContext from "../context/AuthContext";
import _ from "lodash";
import {
    Table,
    Button,
    Alert,
    Container,
    InputGroup,
    Form,
} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCheck,
    faX,
    faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import Spinner from "react-bootstrap/Spinner";
import ModalComp from "../components/ModalComp";
import PaginationComp from "../components/PaginationComp";

function Manage() {
    const {
        usersList,
        newFbPassword,
        setNewFbPassword,
        generateProject,
        generateProjectError,
        applicationsList,
        setGenerateProjectError,
    } = useContext(AuthContext);

    const [showAlert, setShowAlert] = useState(false);
    const [searchResults, setSearchResults] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [noResults, setNoResults] = useState(false);
    const [page, setPage] = useState(1);
    const [currentUsersList, setCurrentUsersList] = useState([])
    const [totalPages, setTotalPages] = useState(0)

    const pageSize = 12;

    function handleTitle(str) {
        const str2 = str.charAt(0).toUpperCase() + str.slice(1);
        const appName = str2.replace("_", " ");
        return appName;
    }

    const localApplications = JSON.parse(localStorage.getItem("applications"));
    const applications = [];
    if (localApplications) {
        for (const app in localApplications) {
            applications.push(app);
        }
    }

    useEffect(() => {
        setShowAlert(newFbPassword ? true : false);
    }, [newFbPassword, generateProject]);

    useEffect(() => {
        const current_list = searchResults ? searchResults : usersList;
        setCurrentUsersList(current_list);
    }, [searchResults, noResults, usersList, page]);

    useEffect(() => {
        const total = (Math.ceil(currentUsersList?.length / pageSize));
        setPage(page <= total ? page : page - 1 || 1);
        setTotalPages(total);
    }, [currentUsersList])

    useEffect(() => {
        setShowAlert(generateProjectError ? true : false);
    }, [generateProjectError]);

    const debounceFn = useCallback(_.debounce(handleDebounceFn, 1000), []);

    function handleDebounceFn(searchvalue) {
        const searchRes = usersList.filter((user) =>
            user.clinic_name.includes(searchvalue)
        );
        if (searchRes.length) {
            setSearchResults(searchRes);
        } else {
            setShowAlert(true);
            setNoResults(true);
        }
    }

    const updateSearch = (value) => {
        debounceFn(value);
        setSearchTerm(value);
    };

    const clearSearch = () => {
        setSearchTerm("");
        setSearchResults(false);
    };

    const handleAlertClose = () => {
        setShowAlert(false);
        setNewFbPassword("");
    };

    const handleErrorAlertClose = () => {
        setShowAlert(false);
        setGenerateProjectError(false);
    };

    const handleNoResultsAlert = () => {
        setSearchTerm("");
        setShowAlert(false);
        setNoResults(false);
    };

    const handleChangePage = useCallback((page) => {
        setPage(page)
    }, [])

    const showPagination = useMemo(() => (currentUsersList?.length > pageSize), [currentUsersList]);
    const indexOfLastUser = useMemo(() => (page * pageSize), [page]);
    const indexOfFirstUser = useMemo(() => (indexOfLastUser - pageSize), [page]);

    return (
        <Fragment>
            <Container className="text-center my-5">
                <h1>Clinics</h1>
                {newFbPassword && (
                    <>
                        <Alert show={showAlert} variant="success">
                            <Alert.Heading>
                                Finished to create your new project!
                            </Alert.Heading>
                            <p>The password for clinic firebase login: </p>
                            <p>{newFbPassword}</p>
                            <hr/>
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() => handleAlertClose()}
                                    variant="outline-success"
                                >
                                    Close
                                </Button>
                            </div>
                        </Alert>
                    </>
                )}

                {generateProjectError && (
                    <>
                        <Alert show={showAlert} variant="danger">
                            <Alert.Heading>Something went wrong!</Alert.Heading>
                            <p>{generateProjectError}</p>
                            <hr/>
                            <div className="d-flex justify-content-end">
                                <Button
                                    variant="outline-danger"
                                    onClick={() => handleErrorAlertClose()}
                                >
                                    Close
                                </Button>
                            </div>
                        </Alert>
                    </>
                )}

                {generateProject && (
                    <>
                        <h3>
                            Generating Project <Spinner animation="grow" size="sm"/>{" "}
                            <Spinner animation="grow" size="sm"/>{" "}
                            <Spinner animation="grow" size="sm"/>
                        </h3>
                    </>
                )}

                <InputGroup
                    className="mb-3"
                    onChange={(e) => updateSearch(e.target.value)}
                >
                    <Form.Control
                        placeholder="Enter clinic name..."
                        aria-label="Recipient's username"
                        aria-describedby="basic-addon2"
                        value={searchTerm || ""}
                        disabled={usersList && false}
                    />
                    {searchResults ? (
                        <Button
                            variant="outline-secondary"
                            id="button-addon2"
                            onClick={clearSearch}
                        >
                            Clear <FontAwesomeIcon icon={faX}/>
                        </Button>
                    ) : (
                        <Button variant="outline-secondary" id="button-addon2">
                            Search <FontAwesomeIcon icon={faMagnifyingGlass}/>
                        </Button>
                    )}
                </InputGroup>

                {noResults && (
                    <>
                        <Alert show={showAlert} variant="danger">
                            <Alert.Heading>No Results Found!</Alert.Heading>
                            <hr/>
                            <div className="d-flex justify-content-end">
                                <Button
                                    onClick={() => handleNoResultsAlert()}
                                    variant="outline-danger"
                                >
                                    Close
                                </Button>
                            </div>
                        </Alert>
                    </>
                )}

                {!_.isEmpty(currentUsersList) ? (
                    <Table striped bordered hover responsive className="table-sm">
                        <thead>
                        <tr>
                            <th>Clinic Name</th>
                            <th>Clinic Email</th>
                            {applicationsList.map((app) => (
                                <th key={app}>{handleTitle(app)}</th>
                            ))}
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                        </thead>
                        <tbody>
                        {currentUsersList.slice(indexOfFirstUser, indexOfLastUser).map((user, index) => (
                            <tr key={`${user.email}|${index}`}>
                                <td>{user.clinic_name}</td>
                                <td>{user.email}</td>
                                {applicationsList.map((app) => (
                                    <td key={`${user.email}|${index}|${app}`}>
                                        {user.applications[app] ? (
                                            <FontAwesomeIcon icon={faCheck}/>
                                        ) : (
                                            <FontAwesomeIcon icon={faX}/>
                                        )}
                                    </td>
                                ))}

                                <td>
                                    <ModalComp user={user} applications={applicationsList}/>
                                </td>
                                <td>
                                    <ModalComp user={user} deleteVerification/>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                ) : (
                    <h3>Clinic list is empty!</h3>
                )}

                {showPagination && <PaginationComp total={totalPages} current={page} onChangePage={handleChangePage}/>}
            </Container>
        </Fragment>
    );
}

export default Manage;

import React from "react";
import {
  Button,
  Row,
  Col,
  ListGroup,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

function ListGroupComp(props) {
  const { array, onClick, isMeasurements } = props;

  if (isMeasurements) {
    return (
      <ListGroup as="ol" className="mt-5">
        {array.map((val) => {
          return (
            <ListGroup.Item
              as="li"
              className="d-flex justify-content-between align-items-start"
              key={`${val.name}|${val.unit}`}
            >
              <div className="ms-2 me-auto">
                <Row>
                  <Col>
                    <OverlayTrigger
                      placement="left"
                      delay={{ show: 150, hide: 200 }}
                      overlay={renderTooltip(isMeasurements)}
                    >
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => {
                          onClick(val.name);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </Button>
                    </OverlayTrigger>
                  </Col>
                  <Col className="align-items-start">
                    <div className="align-items-start fw-bold">{val.name}</div>
                    <div className="align-items-start fw-bold">{val.unit}</div>
                  </Col>
                </Row>
              </div>
            </ListGroup.Item>
          );
        })}
      </ListGroup>
    );
  }

  return (
    <ListGroup as="ol" className="mt-5">
      {array.map((val) => {
        return (
          <ListGroup.Item
            as="li"
            className="d-flex justify-content-between align-items-start"
            key={`${val}`}
          >
            <div className="ms-2 me-auto">
              <Row>
                <Col>
                  <OverlayTrigger
                    placement="left"
                    delay={{ show: 150, hide: 200 }}
                    overlay={renderTooltip}
                  >
                    <Button
                      variant="danger"
                      className="btn-sm align-items-end"
                      onClick={() => {
                        onClick(val);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </Button>
                  </OverlayTrigger>
                </Col>
                <Col>
                  <div className="fw-bold">{val}</div>
                </Col>
              </Row>
            </div>
          </ListGroup.Item>
        );
      })}
    </ListGroup>
  );
}

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Delete Value
  </Tooltip>
);

export default ListGroupComp;

import React, {useState, useCallback, useMemo} from "react";
import {Button, Form, Card, ListGroup, InputGroup, Row, Col} from "react-bootstrap";
import {validate} from "react-email-validator";
import AccordionComp from "./AccordionComp";
import ManagementGuide from "../Documents/ManagementGuide.pdf";
import Select from "react-select";
import _, {negate, pickBy, isNil, isEqual} from "lodash";
import {formInputs, firebaseInputs, chatInputs, proxyInputs} from '../utils/formInputs'

const isDifferent = negate(isEqual);

function UserUpdateForm(props) {
    const {
        formSubmitHandler,
        modalSubmitHandler,
        user: userFromProps,
        applications,
    } = props;


    const localMeasurements = JSON.parse(localStorage.getItem("measurements"));

    const measurementsNames = [];
    const measurementsTypes = [];
    if (localMeasurements) {
        for (const meas in localMeasurements) {
            measurementsNames.push(meas);
            measurementsTypes.push(localMeasurements[meas]);
        }
    }

    const options = measurementsNames.map((meas) => {
        return {value: meas, label: meas};
    });

    const [user, setUser] = useState(userFromProps ?? {});
    const [selectOptions, setSelectOptions] = useState(options);
    const [fb_config, setFbConfig] = useState("");
    const [fb_chat_config, setFbChatConfig] = useState("");
    const [newMeasurement, setNewMeasurement] = useState({name: "", unit: ""});
    const [userEnterdMeasurements, setUserEnterdMeasurements] = useState({});

    const clinicPreDefinedMeasurements = [];
    if (user.measurementsTypes) {
        for (const meas in user.measurementsTypes) {
            clinicPreDefinedMeasurements.push({value: meas, label: meas});
            options.push({value: meas, label: meas});
        }
    }

    const showFirebaseConfig = useMemo(() => negate(Boolean)(user.fb_authDomain || user.fb_databaseURL || user.fb_storageBucket || user.fb_apiKey),
        [user.fb_authDomain, user.fb_databaseURL, user.fb_storageBucket, user.fb_apiKey]
    );

    const showChatFirebaseConfig = useMemo(() => negate(Boolean)(
            user.chat_authDomain || user.chat_databaseURL || user.chat_storageBucket || user.chat_apiKey ||
            user.chat_projectId || user.chat_appId || user.chat_messagingSenderId
        ),
        [user.chat_authDomain, user.chat_databaseURL, user.chat_storageBucket, user.chat_apiKey, user.chat_projectId, user.chat_appId, user.chat_messagingSenderId]
    );


    const isDirty = useMemo(() => {
        return isDifferent(user, userFromProps ?? {});
    }, [user, fb_config, fb_chat_config]);

    const urlErrors = useCallback((url) => {
        if (url && !url.match(/^https?:\/\/.*\//)) {
            return "The provided string is not in the required format! - http://my.domain/ or https://my.domain/";
        }
        return undefined;
    }, []);

    const isRequiredValidator = useCallback((value, fieldName) => {
        if (!value || value === "") {
            return `${fieldName} cannot be blank!`;
        }
        return undefined;
    }, []);

    const errors = useMemo(() => {
                const newErrors = {};

                newErrors.email = isRequiredValidator(user.email, "Clinic email");
                if (!validate(user.email)) newErrors.email = "Invalid Email";

                newErrors.clinic_name = isRequiredValidator(user.clinic_name, "Clinic name");
                newErrors.clinic_code = isRequiredValidator(user.clinic_code, "Clinic code");

                // firebase
                if (showFirebaseConfig) {
                    if (fb_config) {
                        newErrors.fb_apiKeyPost = isRequiredValidator(
                            user.fb_apiKeyPost,
                            "Api key post"
                        );
                    }
                } else {
                    newErrors.fb_authDomain = isRequiredValidator(user.fb_authDomain, "authDomain");
                    newErrors.fb_databaseURL = isRequiredValidator(user.fb_databaseURL, "databaseURL");
                    newErrors.fb_storageBucket = isRequiredValidator(user.fb_storageBucket, "storageBucket");
                    newErrors.fb_apiKey = isRequiredValidator(user.fb_apiKey, "apiKey")
                    newErrors.fb_apiKeyPost = isRequiredValidator(user.fb_apiKeyPost, "Api key post");
                }

                if (!showChatFirebaseConfig) {
                    newErrors.chat_authDomain = isRequiredValidator(user.chat_authDomain, "chat authDomain");
                    newErrors.chat_databaseURL = isRequiredValidator(user.chat_databaseURL, "chat databaseURL");
                    newErrors.chat_storageBucket = isRequiredValidator(user.chat_storageBucket, "chat storageBucket");
                    newErrors.chat_apiKey = isRequiredValidator(user.chat_apiKey, "chat apiKey");
                    newErrors.chat_messagingSenderId = isRequiredValidator(user.chat_messagingSenderId, "chat messagingSenderId");
                    newErrors.chat_projectId = isRequiredValidator(user.chat_projectId, "chat projectId");
                    newErrors.chat_appId = isRequiredValidator(user.chat_appId, "chat appId");
                }


                newErrors.measurementsTypes = isEqual(user.measurementsTypes, {})
                    ? "You must select at least one clinic measurement!"
                    : undefined;

                newErrors.public_url = urlErrors(user.public_url);
                newErrors.private_url = urlErrors(user.private_url);

                if (showFirebaseConfig) {
                    if (!fb_config || fb_config === "")
                        newErrors.fb_config =
                            "Firebase config cannot be blank if firebase props changed selected!";

                    if (!user.fb_apiKeyPost || user.fb_apiKeyPost === "")
                        newErrors.fb_apiKeyPost =
                            "Firebase chat cloud messaging api aka api key post cannot be blank if firebase props changed selected!";
                }

                if (showChatFirebaseConfig) {
                    if (!fb_chat_config || fb_chat_config === "")
                        newErrors.fb_chat_config =
                            "Firebase chat config cannot be blank if firebase props changed selected!";
                }

                if (!user.measurementsTypes || _.isEmpty(user.measurementsTypes))
                    newErrors.measurementsTypes =
                        "Clinic must have at least one measurement type!";

                return pickBy(newErrors, negate(isNil));
            }
            ,
            [user, fb_config, fb_chat_config]
        )
    ;

    const hasErrors = useMemo(() => Object.keys(errors).length > 0, [errors]);

    const onChangeApplications = (e) => {
        setUser(user => ({
            ...user,
            applications: {
                ...user.applications,
                [e.target.name]: !user.applications[e.target.name],
            }
        }));
    };

    const handleOptionsChange = (selectedOptions) => {
        const newOptions = selectedOptions.reduce(
            (accumulator, {value}) => ({
                ...accumulator,
                [value]: localMeasurements[value] || userEnterdMeasurements[value],
            }),
            {}
        );
        setUser(user => ({...user, measurementsTypes: newOptions}))
    };

    const saveNewMeasurement = () => {
        const newMeas = {};
        newMeas[newMeasurement.name] = newMeasurement.unit;
        setUserEnterdMeasurements({...userEnterdMeasurements, ...newMeas});
        setSelectOptions([...selectOptions, createOption(newMeasurement.name)]);
        setNewMeasurement({name: "", unit: ""});
    };

    const createOption = (label) => ({
        value: label,
        label,
    });

    const submitHandler = (generate) => (e) => {
        e.preventDefault();
        if (hasErrors) {
            return;
        } else {
            formSubmitHandler(
                user,
                fb_config,
                fb_chat_config,
                generate
            );
            modalSubmitHandler();
        }
    };

    return (
        <>
            <Form className="text-center ">
                <h5 className="my-3">Clinic Props:</h5>
                {formInputs.map(input => <UserFormInput {...input} user={user} setUser={setUser} errors={errors}/>)}

                {showFirebaseConfig || showChatFirebaseConfig && <>
                    <AccordionComp
                        title={"Firebase Project Init Guide"}
                        body={fbAccordionBody()}
                    />
                </>}

                <h5 className="my-3">Firebase Props:</h5>
                {showFirebaseConfig ? <>
                    <Form.Group controlId="fb_config" className="my-2">
                        <Form.Label>Firebase Config</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Copy the firebase config propertis from your firebase app settings."
                            value={fb_config}
                            onChange={(e) => setFbConfig(e.target.value)}
                            as="textarea"
                            rows={6}
                            isInvalid={!!errors.fb_config}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.fb_config}
                        </Form.Control.Feedback>
                    </Form.Group>

                </> : <>
                    {firebaseInputs.map(input => <UserFormInput {...input} user={user} setUser={setUser}
                                                                errors={errors}/>)}
                </>
                }

                <UserFormInput userKey={'fb_apiKeyPost'} label={'Api key post - Cloud Messaging'}
                               placeholder={'Cloud messaging key - api key post'} rows={3} user={user}
                               setUser={setUser} errors={errors}/>

                <h5 className="my-3">Firebase Chat Props:</h5>
                {showChatFirebaseConfig ? <>
                    <Form.Group controlId="fb_chat_config" className="my-2">
                        <Form.Label>Firebase Chat Config</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Copy the chat firebase config propertis from your firebase app settings."
                            value={fb_chat_config}
                            onChange={(e) => setFbChatConfig(e.target.value)}
                            as="textarea"
                            rows={6}
                            isInvalid={!!errors.fb_chat_config}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors.fb_chat_config}
                        </Form.Control.Feedback>
                    </Form.Group>
                </> : <>
                    {chatInputs.map(input => <UserFormInput {...input} user={user} setUser={setUser}
                                                            errors={errors}/>)}
                </>
                }

                <h5 className="my-3">Proxy Props:</h5>
                {proxyInputs.map(input => <UserFormInput {...input} user={user} setUser={setUser}
                                                         errors={errors}/>)}


                <Form.Group className="my-4 square border border-2 text-center p-">
                    <Form.Label className="mb-2">Apps:</Form.Label>
                    <br/>
                    {applications.map((app) => (
                        <Form.Check
                            key={`${app}|${user.userId}`}
                            name={app}
                            label={app}
                            inline
                            defaultChecked={
                                app === "accounts" ||
                                app === "dashboard" ||
                                user.applications[app]
                            }
                            onChange={onChangeApplications}
                            disabled={app === "accounts" || app === "dashboard"}
                        />
                    ))}
                </Form.Group>

                <Form.Group className="my-4 text-center">
                    <Form.Label className="mb-2">Measurements:</Form.Label>
                    <Form.Control
                        style={{
                            visibility: "hidden",
                            height: 0,
                            width: 0,
                            margin: 0,
                            padding: 0,
                        }}
                        type="text"
                        onChange={() => {
                        }}
                        isInvalid={errors.measurementsTypes}
                    />
                    <Select
                        isClearable={false}
                        defaultValue={clinicPreDefinedMeasurements}
                        onChange={handleOptionsChange}
                        options={selectOptions}
                        className={
                            errors.measurementsTypes ? "form-control is-invalid" : ""
                        }
                        isMulti
                        styles={{zIndex: 100}}
                    />
                    <Form.Control.Feedback type="invalid">
                        {errors.measurementsTypes}
                    </Form.Control.Feedback>
                </Form.Group>

                <InputGroup
                    className="my-4 text-center justify-content-center"
                    style={{zIndex: 0}}
                >
                    <Form.Group>
                        <Form.Label className="mb-2">Add Custom Measurement:</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Measurement name"
                            value={newMeasurement.name}
                            onChange={(e) =>
                                setNewMeasurement({...newMeasurement, name: e.target.value})
                            }
                        />
                        <Form.Control
                            className="mt-2"
                            type="text"
                            placeholder="Measurement value type"
                            value={newMeasurement.unit}
                            onChange={(e) =>
                                setNewMeasurement({...newMeasurement, unit: e.target.value})
                            }
                        />
                        <Button
                            className="mt-3 text-center"
                            variant="secondary"
                            onClick={saveNewMeasurement}
                        >
                            Save Measurement
                        </Button>
                    </Form.Group>
                </InputGroup>

                <Row>
                    <Col>
                        <Button
                            className="mt-3 text-center w-75"
                            type="button"
                            variant="primary"
                            onClick={submitHandler(false)}
                            disabled={hasErrors || !isDirty || showFirebaseConfig || showChatFirebaseConfig}
                        >
                            Save
                        </Button>

                    </Col>
                    <Col>
                        <Button
                            className="mt-3 text-center w-75"
                            type="button"
                            variant="primary"
                            onClick={submitHandler(true)}
                            disabled={hasErrors || !isDirty}
                        >
                            Save And Generate
                        </Button>
                    </Col>
                </Row>
            </Form>
        </>
    );
}

const UserFormInput = ({user, userKey, rows = 1, type = 'text', label, placeholder, setUser, errors}) => {
    return (
        <>
            <Form.Group controlId={userKey} className="my-2" key={`${rows}-${label}-${userKey}`}>
                <Form.Label><span style={{whiteSpace: 'pre-line'}}>{label}</span></Form.Label>
                <Form.Control
                    type={type}
                    placeholder={placeholder}
                    value={user[userKey]}
                    onChange={(e) => setUser(user => ({...user, [userKey]: e.target.value}))}
                    isInvalid={!!errors[userKey]}
                    rows={rows}
                    {...(rows > 1 ? {as: "textarea"} : {})}
                />
                <Form.Control.Feedback type="invalid">
                    {errors[userKey]}
                </Form.Control.Feedback>
            </Form.Group>
        </>
    )
}

const fbAccordionBody = () => {
    return (
        <>
            <p>
                It seems like you didn't set any Firebase project for this clinic yet.
            </p>
            <p>Open the pdf guide and follow the steps:</p>
            <ListGroup>
                <ListGroup.Item>
                    <strong>
                        <Card.Link href={ManagementGuide} target="_blank">
                            Download Pdf Guide
                        </Card.Link>
                    </strong>
                </ListGroup.Item>
            </ListGroup>
        </>
    );
};

export default UserUpdateForm;

import React from "react";
import Accordion from "react-bootstrap/Accordion";

function AccordionComp(props) {
  const { title, body } = props;
  return (
    <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>{body}</Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default AccordionComp;

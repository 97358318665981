import React, {
  Fragment,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import AuthContext from "../context/AuthContext";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import FormContainer from "../components/FormContainer";
import ListGroupComp from "../components/ListGroupComp";
import _ from "lodash";

function Applications() {
  const { addApplication, removeApplication, applicationsList } =
    useContext(AuthContext);

  const [applicationName, setApplicationName] = useState("");

  const saveNewApplication = () => {
    addApplication(applicationName);
    setApplicationName("");
  };

  const deleteApplication = (app_name) => {
    removeApplication(app_name);
  };

  return (
    <Fragment>
      <Container className="text-center my-5">
        <h1>Manage Applications</h1>
        <Row className="mt-5">
          <Col xs lg="5">
            <h3>Current Applications</h3>
            <ListGroupComp
              array={applicationsList}
              onClick={deleteApplication}
            />
          </Col>
          <Col>
            <h3>Add Application</h3>
            <FormContainer>
              <Form.Label className="mb-2">Add Application:</Form.Label>
              <Form.Control
                type="text"
                placeholder="Application Name"
                value={applicationName}
                onChange={(e) => setApplicationName(e.target.value)}
              ></Form.Control>
              <Button
                className="mt-3 text-center"
                variant="secondary"
                onClick={saveNewApplication}
              >
                Save Application
              </Button>
            </FormContainer>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default Applications;

import React from "react";

function Svg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
      <defs>
        <clipPath id="freepik--clip-path--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M293.19 158.8s.2 5.26-1 8.37-.58 9.74-.19 10.9-1.17 5.07-1.17 5.07l3.7 5.06s4.28-6.43 6.42-9.93 5.29-6.62 4.28-8.57-4.08-5.84-4.28-6.81-2-1.94-2-1.94l-3.11 7s-.58-5.06-.58-7.39-1.48-3.51-2.07-1.76z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-2--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M290.08 182l7.79 7.78s-24.53 40.69-27.06 41.85-12.07-6.63-12.07-6.63l-6.43-35.44s8.58 4.61 11.5 9.47 4.86 5.33 4.86 5.33z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-3--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M216.31 176.46s-9.15 8.37-9.93 9-7.79 12.06-9.73 16.54-.2 12.85 1 20 8.76 30.17 8.76 30.17l-1.95 58.2s13.43 5.65 21 7 19.66.78 19.66.78l2.53-12.65 3.11 12.06s11.87-1.36 17.32-7.39L261.47 236l-3.7-33.29s-1-8-2.92-11.48-12.46-10.32-19.86-13-18.68-1.77-18.68-1.77z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-4--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M222.34 133.44s-7.78 3.51-11.09 9.74 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75.19 8.76L234 185.22l3.89-12.46s6.62-1.36 7.6-3.89-1.95-18.1-2.73-21.22-3.11-9.14-3.11-9.14.77-2.92-3.7-5.65-13.61.58-13.61.58z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-5--inject-20">
          <path d="M222.34 133.44s-7.78 3.51-11.09 9.74 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75.19 8.76L234 185.22l3.89-12.46s6.62-1.36 7.6-3.89-1.95-18.1-2.73-21.22-3.11-9.14-3.11-9.14.77-2.92-3.7-5.65-13.61.58-13.61.58z"></path>
        </clipPath>
        <clipPath id="freepik--clip-path-6--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M242.78 147.65l-1.17 2.14s4.67 4.29 4.48 5.45-3.89 2.54-3.89 2.54"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-7--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M262.25 183.14s1-7 1-7.6S263 165 263 165s3.7-8 4.29-8.37 6.81-3.5 7.2-2.14-1.36 1.95-1.95 2.53-3.11 1.75-3.31 2.34-1.36 5.06-1.36 5.06 5.06-3.89 6.42-3.89 2.53 3.11 2.53 3.11 1.76 2.53 1.76 3.89.39 2.92-.59 3.9-1.36 1.75-2.72 1.75-3.12 1.56-3.12 2.72 0 5.06-1.17 6.23a70.29 70.29 0 01-5.64 4.28z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-8--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M301.37 161.92s-4.28 2.53-4.28 3.7 1 1.36 2.33.78 3.9-1.37 3.9-2.73-.59-1.95-1.95-1.75z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-9--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M300.59 159.19s-4.09 1-4.28 2.53 5.06.39 6.23 0 1.17-3.11-1.95-2.53z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-10--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M302.73 165s-4.86 2.34-5.06 3.12-.58 1.94 1.36 1.94 5.65-1.94 5.84-3.31-.58-1.75-2.14-1.75z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-11--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M304.26 167.76s-4.5 2.06-4.68 2.75-.54 1.72 1.26 1.72 5.22-1.72 5.4-2.92-.54-1.55-1.98-1.55z"
          ></path>
        </clipPath>
        <clipPath id="freepik--clip-path-12--inject-20">
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M215.33 189.36s2.92 0 6.43 3.12 16 15.77 16 15.77l24.33-26.48 8 7.59-28.87 42.24a11.36 11.36 0 01-4.28.59c-2.14-.2-34.84-16.35-34.84-16.35"
          ></path>
        </clipPath>
      </defs>
      <g>
        <path
          fill="#407BFF"
          d="M52.38 267.26s2.28 68.45 58.62 109.26 134.42 37.24 197.6 36 111.64-41.84 118.87-97.49-41.06-67.56-67.35-127.87-24-81.1-79.46-114.75S138.58 70.06 93.23 140 52.38 267.26 52.38 267.26z"
        ></path>
        <path
          fill="#fff"
          d="M52.38 267.26s2.28 68.45 58.62 109.26 134.42 37.24 197.6 36 111.64-41.84 118.87-97.49-41.06-67.56-67.35-127.87-24-81.1-79.46-114.75S138.58 70.06 93.23 140 52.38 267.26 52.38 267.26z"
          opacity="0.7"
        ></path>
      </g>
      <path
        fill="#407BFF"
        d="M375 152.06a9.46 9.46 0 00-2.15 4.46l-4.29 2.41v-11.39l.08.11 6.85-5a12.14 12.14 0 007.21-1.12c3.52-1.89 6.08-8.38 6.08-8.38s-8.38-.95-11.22 2.57a9.78 9.78 0 00-2.33 6.55l-6.67 4.89v-8.41a9.29 9.29 0 005.08-6.14c1.22-4.73-5.27-14.06-5.27-14.06S361.6 128.14 363 133a8.16 8.16 0 005.14 5.74V157l-4.82-4a9.05 9.05 0 00-2.48-7c-3.24-3.65-11.89-3.38-11.89-3.38s3.65 7.3 6.75 9.73c2.72 2.13 6.47 1.26 7.37 1l5.07 4.23v5.54h-7.53l3.07 19.22h9.23l3.07-19.22h-7.38v-3.67l4.72-2.64a10.69 10.69 0 007.39-.55 23.77 23.77 0 006.9-5.94S378.5 148 375 152.06z"
      ></path>
      <g>
        <path fill="#fff" d="M217 113.79H318.28V122.02000000000001H217z"></path>
        <path fill="#fff" d="M217 126.13H318.28V134.35999999999999H217z"></path>
        <path fill="#fff" d="M217 138.47H318.28V146.7H217z"></path>
        <path fill="#fff" d="M217 150.82H318.28V159.04999999999998H217z"></path>
        <path fill="#fff" d="M217 163.16H318.28V171.39H217z"></path>
        <path fill="#fff" d="M217 175.5H318.28V183.73H217z"></path>
        <path fill="#fff" d="M217 187.84H318.28V196.07H217z"></path>
        <path fill="#fff" d="M217 200.18H318.28V208.41H217z"></path>
        <path fill="#fff" d="M217 212.52H318.28V220.75H217z"></path>
        <path fill="#fff" d="M217 224.86H318.28V233.09H217z"></path>
        <path fill="#fff" d="M217 237.2H318.28V245.42999999999998H217z"></path>
        <path fill="#fff" d="M217 249.54H318.28V257.77H217z"></path>
        <path fill="#fff" d="M217 261.89H318.28V270.12H217z"></path>
        <path
          fill="#fff"
          d="M109.02 113.79H210.3V122.02000000000001H109.02z"
        ></path>
        <path
          fill="#fff"
          d="M109.02 126.13H210.3V134.35999999999999H109.02z"
        ></path>
        <path fill="#fff" d="M109.02 138.47H210.3V146.7H109.02z"></path>
        <path
          fill="#fff"
          d="M109.02 150.82H210.3V159.04999999999998H109.02z"
        ></path>
        <path fill="#fff" d="M109.02 163.16H210.3V171.39H109.02z"></path>
        <path fill="#fff" d="M109.02 175.5H210.3V183.73H109.02z"></path>
        <path fill="#fff" d="M109.02 187.84H210.3V196.07H109.02z"></path>
        <path fill="#fff" d="M109.02 200.18H210.3V208.41H109.02z"></path>
        <path fill="#fff" d="M109.02 212.52H210.3V220.75H109.02z"></path>
        <path fill="#fff" d="M109.02 224.86H210.3V233.09H109.02z"></path>
        <path
          fill="#fff"
          d="M109.02 237.2H210.3V245.42999999999998H109.02z"
        ></path>
        <path fill="#fff" d="M109.02 249.54H210.3V257.77H109.02z"></path>
        <path fill="#fff" d="M109.02 261.89H210.3V270.12H109.02z"></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M103.98 105.67H323.31V111.67H103.98z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M94.13 309.33H104.13V432H94.13z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M324.8 309.33H334.8V432H324.8z"
        ></path>
        <path
          fill="#407BFF"
          stroke="#407BFF"
          strokeMiterlimit="10"
          d="M107.47 279.33H322a14.48 14.48 0 0114.48 14.48v21.77h0H93h0v-21.76a14.48 14.48 0 0114.47-14.49z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M113.35 305.08L329.49 305.08"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M96.58 305.08L102.57 305.08"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M76.13 368.67H86.13V432H76.13z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M143.47 368.67H153.47V432H143.47z"
        ></path>
        <path
          fill="#407BFF"
          stroke="#407BFF"
          strokeMiterlimit="10"
          d="M89.47 338.67h51.15a14.48 14.48 0 0114.48 14.48v21.77h0H75h0v-21.77a14.48 14.48 0 0114.47-14.48z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M362.8 192.33H409.47V414.33000000000004H362.8z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M434.8 192.33L414.8 192.33 414.8 414.33 434.8 414.33"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M355.47 431.67L355.47 182.33 418.13 182.33"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M400.13 290.33H405.46V311H400.13z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeMiterlimit="10"
          d="M418.8 290.33H424.13V311H418.8z"
        ></path>
      </g>
      <g>
        <path
          fill="#fff"
          d="M293.19 158.8s.2 5.26-1 8.37-.58 9.74-.19 10.9-1.17 5.07-1.17 5.07l3.7 5.06s4.28-6.43 6.42-9.93 5.29-6.62 4.28-8.57-4.08-5.84-4.28-6.81-2-1.94-2-1.94l-3.11 7s-.58-5.06-.58-7.39-1.48-3.51-2.07-1.76z"
        ></path>
        <g clipPath="url(#freepik--clip-path--inject-20)">
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M293.19 158.8s.2 5.26-1 8.37-.58 9.74-.19 10.9-1.17 5.07-1.17 5.07l3.7 5.06s4.28-6.43 6.42-9.93 5.29-6.62 4.28-8.57-4.08-5.84-4.28-6.81-2-1.94-2-1.94l-3.11 7s-.58-5.06-.58-7.39-1.48-3.51-2.07-1.76z"
            opacity="0.15"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M293.19 158.8s.2 5.26-1 8.37-.58 9.74-.19 10.9-1.17 5.07-1.17 5.07l3.7 5.06s4.28-6.43 6.42-9.93 5.29-6.62 4.28-8.57-4.08-5.84-4.28-6.81-2-1.94-2-1.94l-3.11 7s-.58-5.06-.58-7.39-1.48-3.51-2.07-1.76z"
        ></path>
        <path
          fill="#fff"
          d="M290.08 182l7.79 7.78s-24.53 40.69-27.06 41.85-12.07-6.63-12.07-6.63l-6.43-35.44s8.58 4.61 11.5 9.47 4.86 5.33 4.86 5.33z"
        ></path>
        <g clipPath="url(#freepik--clip-path-2--inject-20)">
          <path
            fill="#407BFF"
            d="M267.19 215.35c-.77 0-1.55-3.37-1.55-5.18s-4.14 1.56-4.14 1.56l-4.65-6.21-1.51.73 3.4 18.74s9.54 7.78 12.07 6.61 24.41-37.47 26.84-41.48c-5.98 5.07-29.78 25.23-30.46 25.23z"
          ></path>
          <path
            fill="#fff"
            d="M267.19 215.35c-.77 0-1.55-3.37-1.55-5.18s-4.14 1.56-4.14 1.56l-4.65-6.21-1.51.73 3.4 18.74s9.54 7.78 12.07 6.61 24.41-37.47 26.84-41.48c-5.98 5.07-29.78 25.23-30.46 25.23z"
            opacity="0.5"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M290.08 182l7.79 7.78s-24.53 40.69-27.06 41.85-12.07-6.63-12.07-6.63l-6.43-35.44s8.58 4.61 11.5 9.47 4.86 5.33 4.86 5.33z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M265.05 217.13c.5-4.5 3.62-12.78 3.62-12.78s-4.12 5.78-5.37 8.78"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M245.05 416a34.53 34.53 0 0010.75 6.25c6.75 2.5 9.75 4 10.75 6.25s0 3.5 0 3.5H223.8s-2.25-3.5 1.5-10.5 19.75-5.5 19.75-5.5z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M246.58 417.48s-3.49 1.53-2.4 2.18 3.92-.87 3.92-.87-3.48 2.62-1.74 2.84 4.36-1.31 4.36-1.31-2.4 3.05-1.31 3.27 3.92-2 3.92-2"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M223.41 428.85L266.55 428.5"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M261.55 309.25s-5 40.75-5.75 50.5-7.5 26.75-8.75 31a99.81 99.81 0 00-2.5 16.25 45.94 45.94 0 001.25 7s.5 2.25-4.25 3.25-13.5 2.5-13.5 2.5-3.25-1-3-10.5.75-27 3-35.25 5.25-21.75 6-24.5-1-48-1-48l28-.25z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M225.3 416a34.53 34.53 0 0010.75 6.25c6.75 2.5 9.75 4 10.75 6.25s0 3.5 0 3.5h-42.75s-2.25-3.5 1.5-10.5 19.75-5.5 19.75-5.5z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M230.45 419.45s-5.45 3.05-4.58 3.48 6.32-2.18 6.32-2.18-3.05 3.49-1.31 3.27 3.71-2.39 3.71-2.39-1.75 3.7-.44 3.27a8.91 8.91 0 002.84-2.18"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M203.51 427.84L246.8 428.5"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M211.05 298.75s1.5 39 1.25 46.5-4.25 15.75-4.75 24.75-4.08 44.39-4.08 48.39.17 4.76.42 5.51 6.41-.9 9.91-2.15 15.52-2.51 15.52-2.51-2-3.74-2-6.49-.5-7.25 0-8.75 3.5-32.75 5.5-38.5 5.5-24.75 5.75-32 3.25-31.75 3.25-31.75-20.02.75-30.77-3z"
        ></path>
        <path
          fill="none"
          stroke="#fff"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M242.69 328.5c.12-1.5.19-2.9.19-4.18M229.32 416.69v-7.62c0-3.82 3.39-22.89 6.35-39.84 2.2-12.56 5.1-25.59 6.45-35.64"
        ></path>
        <path
          fill="#fff"
          d="M216.31 176.46s-9.15 8.37-9.93 9-7.79 12.06-9.73 16.54-.2 12.85 1 20 8.76 30.17 8.76 30.17l-1.95 58.2s13.43 5.65 21 7 19.66.78 19.66.78l2.53-12.65 3.11 12.06s11.87-1.36 17.32-7.39L261.47 236l-3.7-33.29s-1-8-2.92-11.48-12.46-10.32-19.86-13-18.68-1.77-18.68-1.77z"
        ></path>
        <g clipPath="url(#freepik--clip-path-3--inject-20)">
          <path
            fill="#407BFF"
            d="M224 227l-19.4-10.34.82-7.47s-6.76-1.06-5.21-5.46 7.79-12.73 9.06-15.54 8.27-8 8.27-8l2.79-3.88c-2.41 0-4 .16-4 .16s-9.15 8.37-9.93 9-7.79 12.06-9.73 16.54-.2 12.85 1 20 8.76 30.17 8.76 30.17l-1.95 58.2s3.19 1.34 7.34 2.87c-.57-21.26-1.59-61.65-1.21-62.22.52-.77 9.57-13.19 12.15-16s-11.37.77-10.6.26S224 227 224 227z"
          ></path>
          <path
            fill="#fff"
            d="M224 227l-19.4-10.34.82-7.47s-6.76-1.06-5.21-5.46 7.79-12.73 9.06-15.54 8.27-8 8.27-8l2.79-3.88c-2.41 0-4 .16-4 .16s-9.15 8.37-9.93 9-7.79 12.06-9.73 16.54-.2 12.85 1 20 8.76 30.17 8.76 30.17l-1.95 58.2s3.19 1.34 7.34 2.87c-.57-21.26-1.59-61.65-1.21-62.22.52-.77 9.57-13.19 12.15-16s-11.37.77-10.6.26S224 227 224 227z"
            opacity="0.5"
          ></path>
          <path
            fill="#fff"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M242.55 217.75s6.69 64.28 5.1 87.77"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M213.05 272.25L233.3 275 233.3 280.25 213.3 277 213.05 272.25z"
          ></path>
          <path
            fill="none"
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M263.8 275.75L257.55 278 256.8 273 261.8 271.25"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M216.31 176.46s-9.15 8.37-9.93 9-7.79 12.06-9.73 16.54-.2 12.85 1 20 8.76 30.17 8.76 30.17l-1.95 58.2s13.43 5.65 21 7 19.66.78 19.66.78l2.53-12.65 3.11 12.06s11.87-1.36 17.32-7.39L261.47 236l-3.7-33.29s-1-8-2.92-11.48-12.46-10.32-19.86-13-18.68-1.77-18.68-1.77z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M215.14 178s17.32 20.83 20.24 26.28 7.21 12.07 7.21 12.07.19-18.5-2.34-27.06-5.25-10.1-5.25-10.1a12.41 12.41 0 00-11.29-3.51c-7.4 1.17-8.57 2.32-8.57 2.32z"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M242.13 202.09l-4.22-8.69v-7.59l-5.84-6-.58 9.73 4.28 4.87 2 14.11c2.43 4.16 4.85 7.89 4.85 7.89s.04-6.98-.49-14.32z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M234.6 184.25l5.65 5.06-4.87-11.68s-2.14 5.45-.78 6.62z"
        ></path>
        <path
          fill="#fff"
          d="M222.34 133.44s-7.78 3.51-11.09 9.74 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75.19 8.76L234 185.22l3.89-12.46s6.62-1.36 7.6-3.89-1.95-18.1-2.73-21.22-3.11-9.14-3.11-9.14.77-2.92-3.7-5.65-13.61.58-13.61.58z"
        ></path>
        <g clipPath="url(#freepik--clip-path-4--inject-20)">
          <g opacity="0.15">
            <path d="M222.34 133.44s-7.78 3.51-11.09 9.74 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75.19 8.76L234 185.22l3.89-12.46s6.62-1.36 7.6-3.89-1.95-18.1-2.73-21.22-3.11-9.14-3.11-9.14.77-2.92-3.7-5.65-13.61.58-13.61.58z"></path>
            <g clipPath="url(#freepik--clip-path-5--inject-20)">
              <path d="M227.63 165.7c-.78-3.88 2.33-14-1-20.69-2.67-5.35-10.4-5-13.46-4.59a15.44 15.44 0 00-1.88 2.76c-3.31 6.23 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75.19 8.76 3.92 3.33v-2.51c0-1.71 7.76-1.81 9.83-2.58s-5.2-1.81-5.97-5.69z"></path>
              <path
                d="M227.63 165.7c-.78-3.88 2.33-14-1-20.69-2.67-5.35-10.4-5-13.46-4.59a15.44 15.44 0 00-1.88 2.76c-3.31 6.23 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75.19 8.76 3.92 3.33v-2.51c0-1.71 7.76-1.81 9.83-2.58s-5.2-1.81-5.97-5.69z"
                opacity="0.5"
              ></path>
            </g>
          </g>
          <path
            d="M227.63 165.7c-.78-3.88 2.33-14-1-20.69-2.67-5.35-10.4-5-13.46-4.59a15.44 15.44 0 00-1.88 2.76c-3.31 6.23 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75.19 8.76 3.92 3.33v-2.51c0-1.71 7.76-1.81 9.83-2.58s-5.2-1.81-5.97-5.69z"
            style={{ mixBlendMode: "multiply" }}
            opacity="0.22"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M222.34 133.44s-7.78 3.51-11.09 9.74 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75.19 8.76L234 185.22l3.89-12.46s6.62-1.36 7.6-3.89-1.95-18.1-2.73-21.22-3.11-9.14-3.11-9.14.77-2.92-3.7-5.65-13.61.58-13.61.58z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M224.48 164.78s.78 3.12 2.14 3.9a20.87 20.87 0 0010.32 2.72"
        ></path>
        <path
          fill="#fff"
          d="M242.78 147.65l-1.17 2.14s4.67 4.29 4.48 5.45-3.89 2.54-3.89 2.54"
        ></path>
        <g clipPath="url(#freepik--clip-path-6--inject-20)">
          <path
            d="M242.78 147.65l-1.17 2.14s4.67 4.29 4.48 5.45-3.89 2.54-3.89 2.54"
            opacity="0.15"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M242.78 147.65l-1.17 2.14s4.67 4.29 4.48 5.45-3.89 2.54-3.89 2.54"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M236.82 151.89L237.13 152.67 234.56 153.69 237.26 153.63"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M237.2 149.08s-3.28-.9-4.65 1.85M244 160.51a17.68 17.68 0 01-4.59.68"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M228 139.67c5.25-3.11 11.68-1.17 11.68-1.17s.77-2.91-3.7-5.64-13.63.58-13.63.58-7.78 3.51-11.09 9.74 1.55 13.82 3.11 15.76a29 29 0 003.7 3.7l1.56 1.75v.71c3.16-.38 3.68-.9 3.68-.9a3.05 3.05 0 01-2.33-1c-1.36-1.17-3.89-3.12-3.31-5.84s2.92-2.73 4.09-1.76 1.17 1.17 2.53.59-.59-4.09-.59-4.09l2-1.36c1.95-1.36 2.53-2.92 1-4.28s-3.97-3.67 1.3-6.79z"
        ></path>
        <path
          fill="#fff"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M217.87 171.21L215.14 178.02 230.71 193.01 234.8 185.03 217.87 171.21z"
        ></path>
        <path
          fill="#263238"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M258.74 123.38s10.12 17.91 11.68 26.47 4.48 19.85 4.48 19.85l25.5-2.92s-3.7-23.74-6-31.72-2.92-9.74-2.92-9.74-28.48-2.92-32.74-1.94z"
        ></path>
        <path
          fill="#fff"
          d="M262.25 183.14s1-7 1-7.6S263 165 263 165s3.7-8 4.29-8.37 6.81-3.5 7.2-2.14-1.36 1.95-1.95 2.53-3.11 1.75-3.31 2.34-1.36 5.06-1.36 5.06 5.06-3.89 6.42-3.89 2.53 3.11 2.53 3.11 1.76 2.53 1.76 3.89.39 2.92-.59 3.9-1.36 1.75-2.72 1.75-3.12 1.56-3.12 2.72 0 5.06-1.17 6.23a70.29 70.29 0 01-5.64 4.28z"
        ></path>
        <g clipPath="url(#freepik--clip-path-7--inject-20)">
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M262.25 183.14s1-7 1-7.6S263 165 263 165s3.7-8 4.29-8.37 6.81-3.5 7.2-2.14-1.36 1.95-1.95 2.53-3.11 1.75-3.31 2.34-1.36 5.06-1.36 5.06 5.06-3.89 6.42-3.89 2.53 3.11 2.53 3.11 1.76 2.53 1.76 3.89.39 2.92-.59 3.9-1.36 1.75-2.72 1.75-3.12 1.56-3.12 2.72 0 5.06-1.17 6.23a70.29 70.29 0 01-5.64 4.28z"
            opacity="0.15"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M262.25 183.14s1-7 1-7.6S263 165 263 165s3.7-8 4.29-8.37 6.81-3.5 7.2-2.14-1.36 1.95-1.95 2.53-3.11 1.75-3.31 2.34-1.36 5.06-1.36 5.06 5.06-3.89 6.42-3.89 2.53 3.11 2.53 3.11 1.76 2.53 1.76 3.89.39 2.92-.59 3.9-1.36 1.75-2.72 1.75-3.12 1.56-3.12 2.72 0 5.06-1.17 6.23a70.29 70.29 0 01-5.64 4.28z"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M278.6 167.56L271.98 170.68"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M276.84 163.67L270.62 167.37"
        ></path>
        <path
          fill="#fff"
          d="M301.37 161.92s-4.28 2.53-4.28 3.7 1 1.36 2.33.78 3.9-1.37 3.9-2.73-.59-1.95-1.95-1.75z"
        ></path>
        <g clipPath="url(#freepik--clip-path-8--inject-20)">
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M301.37 161.92s-4.28 2.53-4.28 3.7 1 1.36 2.33.78 3.9-1.37 3.9-2.73-.59-1.95-1.95-1.75z"
            opacity="0.15"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M301.37 161.92s-4.28 2.53-4.28 3.7 1 1.36 2.33.78 3.9-1.37 3.9-2.73-.59-1.95-1.95-1.75z"
        ></path>
        <path
          fill="#fff"
          d="M300.59 159.19s-4.09 1-4.28 2.53 5.06.39 6.23 0 1.17-3.11-1.95-2.53z"
        ></path>
        <g clipPath="url(#freepik--clip-path-9--inject-20)">
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M300.59 159.19s-4.09 1-4.28 2.53 5.06.39 6.23 0 1.17-3.11-1.95-2.53z"
            opacity="0.15"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M300.59 159.19s-4.09 1-4.28 2.53 5.06.39 6.23 0 1.17-3.11-1.95-2.53z"
        ></path>
        <path
          fill="#fff"
          d="M302.73 165s-4.86 2.34-5.06 3.12-.58 1.94 1.36 1.94 5.65-1.94 5.84-3.31-.58-1.75-2.14-1.75z"
        ></path>
        <g clipPath="url(#freepik--clip-path-10--inject-20)">
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M302.73 165s-4.86 2.34-5.06 3.12-.58 1.94 1.36 1.94 5.65-1.94 5.84-3.31-.58-1.75-2.14-1.75z"
            opacity="0.15"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M302.73 165s-4.86 2.34-5.06 3.12-.58 1.94 1.36 1.94 5.65-1.94 5.84-3.31-.58-1.75-2.14-1.75z"
        ></path>
        <path
          fill="#fff"
          d="M304.26 167.76s-4.5 2.06-4.68 2.75-.54 1.72 1.26 1.72 5.22-1.72 5.4-2.92-.54-1.55-1.98-1.55z"
        ></path>
        <g clipPath="url(#freepik--clip-path-11--inject-20)">
          <path
            stroke="#263238"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M304.26 167.76s-4.5 2.06-4.68 2.75-.54 1.72 1.26 1.72 5.22-1.72 5.4-2.92-.54-1.55-1.98-1.55z"
            opacity="0.15"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M304.26 167.76s-4.5 2.06-4.68 2.75-.54 1.72 1.26 1.72 5.22-1.72 5.4-2.92-.54-1.55-1.98-1.55z"
        ></path>
        <path
          fill="#7d7d7d"
          d="M266.18 125.93s8.12 14.15 9.62 19.9 2.75 21.75 2.75 21.75l16.25-2s-.5-14.5-2.75-21.5-4-16-4-16z"
        ></path>
        <path
          fill="#fff"
          d="M279.65 147.27s-1.86 3.46-.8 4.93 3.59 2.13 3.86 2.53 2.13 11.71 2.13 11.71l5.72-.53s-2.79-10.52-2.13-12.51 2.27-3.86.67-5.59-4.13-2.93-5.72-2.67a10.34 10.34 0 00-3.73 2.13z"
        ></path>
        <path
          fill="#fff"
          d="M274.46 127.17s4.66 12 4.26 14.11-1.47 5.59.93 6 3.46-1.86 4.26-1.73 2.13 2.66 3.33 1.2 2.39-3.86.4-6.26-3.46-3.72-4.53-6.25a66.8 66.8 0 01-2.13-6.39zM215.33 189.36s2.92 0 6.43 3.12 16 15.77 16 15.77l24.33-26.48 8 7.59-28.87 42.24a11.36 11.36 0 01-4.28.59c-2.14-.2-34.84-16.35-34.84-16.35"
        ></path>
        <g clipPath="url(#freepik--clip-path-12--inject-20)">
          <path
            fill="#407BFF"
            d="M233.06 225.69c-4.4 0-19.14-8.28-23.79-11.9a15.39 15.39 0 01-3.84-4.62l-3.33 6.67s32.7 16.15 34.84 16.35a11.36 11.36 0 004.28-.59l20.86-30.6c-7.52 7.38-25.56 24.69-29.02 24.69z"
          ></path>
          <path
            fill="#fff"
            d="M233.06 225.69c-4.4 0-19.14-8.28-23.79-11.9a15.39 15.39 0 01-3.84-4.62l-3.33 6.67s32.7 16.15 34.84 16.35a11.36 11.36 0 004.28-.59l20.86-30.6c-7.52 7.38-25.56 24.69-29.02 24.69z"
            opacity="0.5"
          ></path>
        </g>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M215.33 189.36s2.92 0 6.43 3.12 16 15.77 16 15.77l24.33-26.48 8 7.59-28.87 42.24a11.36 11.36 0 01-4.28.59c-2.14-.2-34.84-16.35-34.84-16.35"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M215.33 222.65L204.63 222.45"
        ></path>
        <path
          fill="none"
          stroke="#263238"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M237.72 207.87s-6.17 7.38-6.92 11.38M235.8 208.63s-5.25 4.75-6.5 7.25"
        ></path>
      </g>
      <path
        fill="none"
        stroke="#263238"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M64.13 432L453.47 432"
      ></path>
    </svg>
  );
}

export default Svg;

import Pagination from 'react-bootstrap/Pagination';

function PaginationComp(props) {
    const {total, current, onChangePage} = props;

    let items = []
    if (current > 1) {
        items.push(<Pagination.First key="first" onClick={() => onChangePage(1)}/>)
        items.push(<Pagination.Prev key="prev" onClick={() => onChangePage(current - 1)}/>)
    }

    for (let page = 1; page <= total; page++) {
        items.push(
            <Pagination.Item key={page} data-page={page} active={page === current} onClick={() => onChangePage(page)}>
                {page}
            < /Pagination.Item>
        )
    }

    if (current < total) {
        items.push(<Pagination.Next key="next" onClick={() => onChangePage(current + 1)}/>)
        items.push(<Pagination.Last key="last" onClick={() => onChangePage(total)}/>)
    }

    return (<Pagination>{items}</Pagination>)
}

export default PaginationComp;
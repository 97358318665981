import React, { useState, useContext } from "react";
import AuthContext from "../context/AuthContext";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import UserUpdateForm from "./UserUpdateForm";
import UserDeleteForm from "./UserDeleteForm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faUserPen } from "@fortawesome/free-solid-svg-icons";

function ModalComp(props) {
  const {user, userId, applications, deleteVerification} = props;
  const { updateUser, deleteUser } = useContext(AuthContext);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    return (
        <>
            <Button variant={deleteVerification ? "danger" : "dark"} className="btn-sm me-2" onClick={handleShow}>
                <FontAwesomeIcon icon={deleteVerification ? faTrash : faUserPen}/>
            </Button>

            <Modal show={show} onHide={handleClose} className="">
                <Modal.Header closeButton className="bg-light rounded">
                    <Modal.Title>{deleteVerification ? (`are you sure tou want to delete ${user.clinic_name} user?`):(user.clinic_name)}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light rounded">
                    {deleteVerification ? (
                        <UserDeleteForm formSubmitHandler={deleteUser} user={user} modalSubmitHandler={handleClose}/>
                    ) : (
                        <UserUpdateForm
                            formSubmitHandler={updateUser}
                            modalSubmitHandler={handleClose}
                            formLabel={"Edit User"}
                            user={user}
                            applications={applications}
                        />
                    )}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default ModalComp;

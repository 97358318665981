import React from "react";
import PrivateRoute from "./utils/PrivateRoute";
import NavbarComp from "./components/NavbarComp";
import Home from "./views/homePage";
import Login from "./views/loginPage";
import Register from "./views/registerPage";
import Manage from "./views/managePage";
import Applications from "./views/applicationsPage";
import Measurements from "./views/measurementsPage";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { AuthProvider } from "./context/AuthContext";

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen overflow-hidden">
        <AuthProvider>
          <NavbarComp />
          <Switch>
            <Route component={Login} path="/login" />
            <PrivateRoute component={Register} path="/register" exact />
            <PrivateRoute component={Manage} path="/manage" exact />
            <PrivateRoute component={Applications} path="/applications" exact />
            <PrivateRoute component={Measurements} path="/measurements" exact />
            <PrivateRoute component={Home} path="/" exact />
          </Switch>
        </AuthProvider>
      </div>
    </Router>
  );
}

export default App;

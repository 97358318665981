import React, { useState, useEffect, useContext } from "react";
import AuthContext from "../context/AuthContext";
import UserForm from "../components/UserForm";
import FormContainer from "../components/FormContainer";

const Register = ({ location, history }) => {
  const { registerUser } = useContext(AuthContext);

  return (
    <FormContainer>
      <UserForm
        formSubmitHandler={registerUser}
        formLabel={"Register"}
      />
    </FormContainer>
  );
};

export default Register;
